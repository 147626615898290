//coffee page css
#coffee {
	@screen sm {
		padding-top: $content-top-offset; }

	section.splash {
		@apply .bg-bottom .bg-cover .bg-no-repeat .relative .flex .justify-center .items-center {}
		min-height: 90vh;
		height: auto;
		background-position: top center;
		padding-top: $header-height-mob;

		.intro {

			.intro-txt {
				// max-width: 80%
				h2 {
					@apply .text-base {}
					@screen lg {
						@apply .text-xl {} } }
				h1 {
					@apply .text-lg {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } } } }
