//typography

html, body {
	color: $grey; }


h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	@apply .font-headings .mb-1 .uppercase .text-orange .font-semibold .tracking-wider {}
	font-size: 1.3rem;

	.sub, &.sub {
		font-size: 1.5rem; } }

section.splash {
	h1, h2, h3, h4, h5 {
		@apply .text-white .tracking-widest {} } }

section.feature {
	h1, h2, h3, h4, h5 {
		@apply .text-white .tracking-widest {} } }

// class applied to 'splash' and 'feature' divs:
.has-bg {
		h1, h2, h3, h4, h5 {
			text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.8); }
		p {
			text-shadow: 0px 0px 20px rgba(0, 0, 0, 1); } }

p {
	@apply .text-base {}
	margin: 0.25rem 0 1rem;
	&:last-child {
		margin: 0.25rem 0; } }

a {
	@apply .text-orange {}
	&:hover {
		text-decoration: underline; } }

ul {
	list-style-type: disc;
	list-style-position: inside; }

.sub {
	@apply .font-subheadings .normal-case .block {}
	transform: rotate(-5deg); }

.title {
	@apply .font-headings .tracking-wider {} }
