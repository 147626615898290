/* Footer Styles: */
footer {
	@apply .py-1 .px-15 .text-white .bg-grey {}
	@screen md {
		@apply .px-0 {} }

	a {
		color: $white; }

	.container {
		@apply .flex .flex-wrap {}

		.f-left {
			flex: 1 0 100%;
			@screen md {
				@apply .pr-5 {}
				flex: 1 1 66.6666%; }

			.subscribe {
				.form-row {
					@apply .flex .flex-wrap .pb-1 {}
					max-width: 100%;

					.form-field {
						@apply .mb-1 {}
						background: transparent;
						border: 1px solid $white;
						border-radius: 8px;
						padding: 0.5rem;
						flex: 1 1 100%;

						@screen md {
							flex: 1 1 45%;
							margin-right: 5%;

							&:last-child {
								@apply .mr-0 {} } }

						&::placeholder {
							@apply .text-white {} } }

					.submit-btn {
						@apply .bg-white .text-darkgrey {}
						flex: 1 1 20%;
						&:hover {} }

					@screen sm {
						max-width: 500px; } } }

			.footer-nav {
				@apply .py-1 {}
				ul {
					list-style-type: none;
					li {
						@apply	.text-base {} } } }

			.social {
				@apply .flex .mt-15 {}
				width: {}
				a {
					@apply .text-center {}
					width: 3rem;
					height: 3rem;
					font-size: 2rem; } }

			.disclaimer {
				@apply .mt-4 .text-sm {}
				@screen md {
					@apply .text-base {} } } }

		.f-right {
			@apply .flex .flex-col {}
			flex: 1 0 100%;
			justify-content: flex-end;
			@screen md {
				flex: 1 1 33.3333%; }
			.logo {
				@apply .text-center .p-2 {}
				@screen md {
					@apply .text-right .p-0 .mb-2 {}
					height: 10rem; }
				svg {
					display: inline-block;
					max-height: 100%;
					max-width: 60%;
					height: auto; } }
			.copy {
				@apply .mt-2 .text-center {}
				@screen md {
					@apply .mt-4 .text-right {} } } } } }

section {
	@apply .p-1 {} }

/* Intro splash pages and welcome sections (for home & wholesale) */
section.splash {
	.intro {
		@apply .text-center .text-white .uppercase {}

		.intro-txt {
			width: 100%;
			.intro-txt {
				// max-width: 80%
				h1,
				h2 > span {
					@apply .text-lg .block {} }
				h2 {
					@apply .text-xl {} }

				@screen lg {
					h1 {
						@apply .text-xl .m-0 {} }
					h2 {
						@apply .leading-tight {}
						font-size: 6rem;
						> span {
							@apply .text-xl .m-0 .leading-none .block {} } } } } }

		.scrollhint {
			@apply .mx-auto {}
			margin-top: 2rem;
			margin-bottom: 5rem;
			@screen xl {
				@apply .w-1/2 {} }

			p {
				@apply .normal-case .text-md {}
				padding: 1rem 0; } } } }

section.welcome {
	@apply .bg-white .flex .flex-wrap .items-stretch {}
	min-height: 60vh;
	height: auto;
	@screen lg {
		min-height: 100vh;
		padding: $container-padding-lg; }
	.image {
		@apply .relative {}
		flex: 1 1 50%;
		display: none;
		background-size: cover;
		background-position: center;
		@screen md {
			flex: 0 1 25%;
			display: block; }
		@screen xxl {
			flex: 0 1 37.5%; } }
	.text-block {
		@apply .flex .flex-col .justify-center {}
		flex: 1 1 100%;
		padding: 0 2.5rem;
		@screen md {
			flex: 1 1 50%; }
		@screen xxl {
			flex: 1 0 25%;
			padding: 0 5rem; } } }

/* Products Menu */
ul.products {
	@apply .px-1 .py-2 {}
	border-top: $border-thickness solid $white;
	border-bottom: $border-thickness solid $white;
	a {
		@apply .text-center {}
		display: block;
		font-size: 1.2rem; }
	> li {
		> a {
			@apply .uppercase .text-md .my-05 .px-3 {}
			font-weight: 600; }
		&.parent {
			> ul {
				margin: 0.5rem 0 1rem; }
			> a {
				@apply .block .relative {}
				&::before, &::after {
					@apply .absolute .block {}
					content: " ";
					width: 2rem;
					height: 2rem;
					background-repeat: no-repeat;
					background-size: contain; }
				&::before {
					@apply .left-0 .top-0 {}
					background: url('../img/arrow_r.svg'); }
				&::after {
					@apply .top-0 .right-0 {}
					background: url('../img/arrow_l.svg'); } } } }

	@screen md {
		@apply .px-0 {} } }

/* Text Blocks */
.text-block {
	@apply .my-2 {}

	h1,h2,h3,h4,h5 {
		@apply .text-center	.text-orange .leading-none .text-md .mb-2 {}
		.sub {
			@apply .leading-loose {}
			margin-bottom: 0.75rem; } }
	.content {
		@apply .py-2 {}
		border-top: $border-thickness solid $orange;
		border-bottom: $border-thickness solid $orange;
		@screen lg {
			@apply .px-15 {}
 } }			// border-bottom: $border-thickness solid $orange

	@screen md {
		@apply .my-0 {} } }

.sign-off {
	@apply .text-center .text-lg .text-white .font-subheadings .leading-tight .px-15 {}
	padding-top: 4rem;
	padding-bottom: 4rem;
	&::before {
		content: " ";
		display: block;
		margin: 0 auto 1.75rem;
		width: 4.5rem;
		height: 2.5rem;
		background-image: url('../img/bread_icon.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat; } }

.orange {
	.text-block {
		.content {
			border-color: $white; } } }


/* Section Divider */
.divider {
	height: 10vh;
	max-height: 100px;
	background-image: url('../img/pattern.gif');
	background-repeat: repeat-x;
	background-size: contain; }

/* Feature Sections */
section.feature {
	@apply .flex {}
	&.full {
		min-height: 100vh; }

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	&.orange {
		@apply .text-white .bg-orange {} }
	&.white {
		@apply .bg-white {} }
	&.black {
		@apply .text-white .bg-black {} }
	&.brown {
		@apply .text-white .bg-brown {} }
	&.grey {
		@apply .bg-grey {} }
	&.dk-grey {
		@apply .text-white .bg-darkgrey {} } }

/* Skala arrow thingo */
.arr {
	@apply .inline-block {}
	width: 1rem;
	height: 1rem;
	background-image: url('../img/arrow_thing.svg');
	background-size: 75%;
	background-position: 50% 66%;
	background-repeat: no-repeat;

	&.arr-circled {
		border-radius: 50%; }

	&.arr-sm {
		width: 0.5rem;
		height: 0.5rem;
		&.arr-circled {
			border: 1px solid $white; } }

	&.arr-md {
		width: 2rem;
		height: 2rem;
		&.arr-circled {
			border: 2px solid $white; } }

	&.arr-lg {
		width: 4rem;
		height: 4rem;
		&.arr-circled {
			border: 3px solid $white; } } }
