//contact page css
#contact {

	section.contact-details {
		@apply .bg-center .bg-cover .bg-no-repeat .relative .flex .justify-center .items-center {}
		background-position: top;

		.intro {

			.intro-txt {
				@apply .py-4 .px-2 {}
				p, ul {
					@apply .uppercase .text-sm .font-semibold .text-white .text-center {} }

				ul {
					li {
						a {
							@apply .text-white {} } } }
				.sub {
					@apply .normal-case .mt-4 {} } }

			@screen sm {
				max-width: 100%; }

			@screen lg {
				.intro-txt {
					p, ul {
						@apply .text-md {} } } }

			@screen xl {
				max-width: 900px; } }

		@screen sm {
			padding-top: $content-top-offset;
			background-position: left $content-top-offset; } }

	#contactMap {
		@apply .h-screen {}
		display: none;
		max-height: 300px;
		@screen md {
			display: block;
			max-height: 550px; } } }
