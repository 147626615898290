//home page css - create one for each page and include to keep shit organised.
#home {

	section.splash {
		@apply .bg-cover .bg-no-repeat .relative .flex .justify-center .items-end {}
		padding-top: $header-height;
		background-image: url('../img/home_splash.jpg');
		background-position: center bottom;
		min-height: 90vh;
		height: auto;

		.shop-links {

			.shop_banner,
			.wholesale_banner {
				@apply .fixed .bottom-0 .w-full .text-base .font-bold .uppercase .text-center .px-1 .py-075 {}
				min-height: 25px;
				z-index: 10;
				transition: width 0.5s;

				@screen md {
					@apply .right-0 .text-left .bottom-auto .py-05 {}
					width: 16rem;
					transition: width 0.5s;

					&:hover {
						width: 18rem; } } }

			.shop_banner {
				@apply .text-white .bg-orange {}
				@screen md {
					top: 15%; } }
			.wholesale_banner {
				@apply .text-orange .bg-white {}
				@screen md {
					top: 19%; } }

			@screen mobile {
				@apply .fixed .bottom-0 .w-full .flex {}
				.shop_banner,
				.wholesale_banner {
					position: relative;
					flex: 1 1 50%; } } }

		.intro {
			width: 100%;
			.intro-txt {
				// max-width: 80%
				h1,
				h2 > span {
					@apply .text-lg .block {} }
				h2 {
					@apply .text-xl {} }

				@screen lg {
					h1 {
						@apply .text-xl .m-0 {} }
					h2 {
						@apply .leading-tight {}
						font-size: 6rem;
						> span {
							@apply .text-xl .m-0 .leading-none .block {} } } }

				@screen xl {
					padding: 0 175px; } } }

		.stamp {
			display: none;
			@screen xl {
				display: block;
				@apply .bg-contain .bg-no-repeat .absolute {}
				width: $stamp-width;
				transform: rotate(10deg);
				height: 0;
				bottom: 10%;
				padding-top: $stamp-width;
				right: $stamp-edge-offset;
				background-image: url('../img/handmade_stamp_wht.svg'); } }

		@screen md {
			@apply .h-screen {} } }

	section#feature1 {
		@apply .flex-col .justify-center {}
		padding-top: 6rem;
		padding-bottom: 6rem;
		.heading {
			@apply .text-center {}
			flex: 0 1 50%;
			h3 {
				@apply .text-lg {}
				margin-top: 0;

				@screen sm {
					margin-top: 7.5%;
					span {
						font-size: $feature-text-medium; } }

				@screen md {
					@apply .text-xl {}
					span {
						@apply	.p-1 .my-0 .mx-auto {}
						display: block;
						max-width: 1350px;
						line-height: $feature-lineheight-huge;
						letter-spacing: 0.8rem; } }

				@screen lg {
					font-size: $feature-text-huge; } } } }

	section.about {
		.container {
			@apply .flex .flex-wrap {}
			@screen md {
				padding-top: inherit;
				padding-bottom: inherit; }
			@screen lg {
				padding: $container-padding-lg; }

			.text-block {
				padding: 0 2.5rem;
				flex: 1 1 100%;

				@screen xl {
					flex: 1 1 33.3333%;
					padding: 0 10rem 0 0; } }

			.image {
				padding: 1rem 2.5rem;
				flex: 1 1 100%;
				@screen lg {
					padding: 2.5rem 2.5rem 0; }
				@screen xl {
					padding: 0px;
					flex: 1 1 66.6666%; } } } }

	section#feature2 {
		@apply .flex-col .items-center .justify-center {}
		min-height: 90vh;
		height: auto;

		.quote {
			p {
				@apply .text-sm .font-semibold .leading-snug .tracking-widest {} }

			@apply	.uppercase .text-center .mx-auto {}
			padding: 1.5rem 0;
			border-top: $border-thickness solid $white;
			border-bottom: $border-thickness solid $white;
			max-width: 860px;
			@screen md {
				p {
					@apply .text-md {} } }
			@screen lg {
				padding: 5rem 0;
				p {
					@apply .text-lg {} } } } }

	section#feature3 {
		@apply .flex-col .text-center {}
		align-items: center;
		justify-content: center;

		h3 {
			font-size: 3rem; }

		p {
			@apply .text-white .text-md {}
			max-width: 1260px; }

		@screen lg {
			h3 {
				font-size: $feature-text-huge; }

			.blurb.h3 {
				p {
					@apply .text-xl {} } } } } }
