header {
	// @apply .text-white .p-1 .fixed .w-full
	@apply .text-white .fixed .w-full .z-20 .top-0 .flex .flex-col .fixed {}
	max-height: $header-height;

	#mLogo {
		@apply .px-1 .py-05 .flex .flex-row .justify-between .bg-orange {}
		max-width: 100%;
		z-index: 1;
		a {
			@apply .text-white {} }

		.mob-logo {
			@apply .flex .flex-col .justify-center {}
			flex: 0 1 90px;
			svg {
				@apply .block {} } }

		.mnav-but {
			@apply .flex .flex-col .items-center .justify-center .uppercase {}
			flex: 0 0 40px;
			height: 60px;
			&:hover {
				cursor: pointer; }
			i {
				font-size: 2rem; } } }

	@screen md {
		@apply .absolute .p-0 .flex-row {}
		height: $header-height;

		#mLogo {
			display: none; } } }
