//base html stuff
html,
body {
	@apply .bg-white .text-black .font-body .h-full .min-h-full .antialiased {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;

	font-size: 90%;

	transition: background-color 0.3s ease;

	&[data-theme="dark"] {
		@apply .bg-black {}
		.barba-container {
			@apply .bg-black {} } }

	&[data-theme="light"] {
		@apply .bg-white {}
		.barba-container {
			@apply .bg-white {} } }

	@screen sm {
		font-size: 100%; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	@apply .w-full {}
	display: block; }

#preload-bar {
	@apply .bg-orange .fixed .w-full .top-0 .left-0 .right-0 .z-30 {}
	height: 3px;

	svg {
		height: 3px; }

	path {
		@apply .stroke-current .text-black {}
		transition: all 0.3s linear; } }

#preload-counter {
	// @apply .fixed .z-30 .top-0 .right-0 .mt-1 .mr-1
	display: none; }

#preload-overlay {
	@apply .bg-white .fixed .w-full .h-full .top-0 .left-0 .z-20 {} }

#barba-wrapper {
	@apply .relative .z-10 {} }

.barba-container {
	@apply .w-full {} }
