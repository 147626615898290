//variables

// colours:
$white: #ffffff;
$black: #000000;
$brown: #7a1f07;
$grey: #717171;
$darkgrey: #717171;
$orange: #ff6841;

$content-top-offset: 10rem;

// sizes:
$header-height-mob: 5rem;
$header-height: 6rem;
$header-logo-offset: 0rem;
$container-padding-lg: 7.5rem 0;

// other:
$border-thickness: 2px;
$feature-text-small: 2rem;
$feature-text-medium: 4rem;
$feature-text-huge: 7rem;
$feature-lineheight-small: 2.1rem;
$feature-lineheight-medium: 4rem;
$feature-lineheight-huge: 7rem;

$stamp-width: 250px;
$stamp-edge-offset: 2rem;

$blurb-bg-colour-dark: rgba(0,0,0,0.5);
$blurb-bg-colour-light: rgba(255, 255, 255, 0.2);
$blurb-bg-colour-orange: rgba(240, 91, 52, 0.3);

// transitions:
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutBounce: cubic-bezier(0.680, 0, 0.265, 1);
