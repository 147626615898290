//wholesale page css
#wholesale {

	section.splash {
		@apply .bg-bottom .bg-cover .bg-no-repeat .relative .flex .justify-center .items-end {}
		padding-top: $header-height-mob;
		min-height: 90vh;
		height: auto;

		.intro {

			.intro-txt {
				// max-width: 80%
				h1 {
					@apply .text-base {}
					@screen lg {
						@apply .text-xl {} } }
				h2 {
					@apply .text-lg {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } }

		@screen sm {
			min-height: 100vh; } }

	section.feature {
		@apply .flex-col {}
		min-height: 60vh;
		height: auto;
		padding-top: 6rem;
		padding-bottom: 6rem;

		.container {
			@apply .h-full {} }

		.feature-blurb {
			@apply .h-full .flex .items-end {}

			p {
				@apply .py-1 .px-15 .text-left .text-sm .text-orange .uppercase .font-semibold {}
				background-color: $blurb-bg-colour-dark;
				border-top: $border-thickness solid $orange;
				border-bottom: $border-thickness solid $orange;
				text-shadow: 0px 0px 30px rgba(0,0,0,0.3);
				max-width: 100%;

				flex: 1 1 100%;

				@screen md {
					@apply .text-md {} } } }

		@screen md {
			@apply .h-screen {}
			.feature-blurb {
				p {
					@apply .text-lg {}
					flex: 0 1 50%; } } } } }
