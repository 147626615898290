//artisan page css
#artisan {

	section.splash {
		@apply .bg-bottom .bg-cover .bg-no-repeat .relative .flex .justify-center .items-end {}
		min-height: 90vh;
		height: auto;
		padding-top: $header-height-mob;

		.intro {

			.intro-txt {
				// max-width: 80%
				h2 {
					@apply .text-base {}
					@screen lg {
						@apply .text-xl {} } }
				h1 {
					@apply .text-lg {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } }

		@screen sm {
			min-height: 100vh; } }

	section.feature {
		@apply .flex-col .justify-center .items-center .py-2 {}
		min-height: 90vh;
		.heading {
			@apply .h-full {}
			h3 {
				@apply .text-base .text-center {}
				@screen lg {
					@apply .text-xl {} }
				span {
					@apply .text-lg .block {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } }
		@screen lg {
			padding-top: 6rem;
			padding-bottom: 6rem; } }

	#artisanblurb {
		.container {
			@apply	.h-full .flex .flex-col .justify-center .items-center {}

			.text-block {
				max-width: 100%;
				@screen lg {
					max-width: 600px; } }

			.sign-off {
				@apply .text-md .font-subheadings .leading-tight .px-15 {}
				@screen lg {
					@apply .text-lg {}
					padding-top: 4rem; } } } } }


//about page css
#about {

	section.splash {
		@apply .bg-bottom .bg-cover .bg-no-repeat .relative .flex .justify-center .items-end {}
		min-height: 90vh;
		height: auto;
		padding-top: $header-height-mob;

		.intro {

			.intro-txt {
				// max-width: 80%
				h1 {
					@apply .text-base {}
					@screen lg {
						@apply .text-xl {} } }
				h2 {
					@apply .text-lg {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } }

		@screen sm {
			min-height: 100vh; } }

	section.feature {
		@apply .flex-col .justify-center .items-center {}
		min-height: 90vh;
		padding-top: 6rem;
		padding-bottom: 6rem;
		.heading {
			@apply .h-full {}
			h3 {
				@apply .text-base .text-center {}
				@screen lg {
					@apply .text-xl {} }
				span {
					@apply .text-lg .block {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge; } } } } }

	#aboutblurb {
		.container {
			@apply	.h-full .flex .flex-col .justify-center .items-center {}

			.text-block {
				max-width: 100%;
				@screen lg {
					max-width: 600px; } } } } }
