//home page css - create one for each page and include to keep shit organised.
#locations {
	@screen sm {
		padding-top: $content-top-offset; }

	section.splash {
		@apply .bg-bottom .bg-cover .bg-no-repeat .relative .flex .justify-center .items-end {}
		min-height: 90vh;
		background-position: top center;
		padding-top: $header-height-mob;

		.intro {

			.intro-txt {
				// max-width: 80%
				h1 {
					@apply .text-base {}
					@screen lg {
						@apply .text-xl {} } }
				h2 {
					@apply .text-lg {}
					@screen lg {
						font-size: $feature-text-huge;
						line-height: $feature-lineheight-huge;
						max-width: 1600px; } } } } }

	section.feature {
		@apply .py-4 {}
		max-width: 100%;
		.container {
			@screen sm {
				@apply .text-center {}
				max-width: 600px; } } }

	section.list {
		ul {
			@apply .flex-row {}
			list-style-type: none;
			li.location {
				@apply .flex .my-4 .items-stretch .flex-wrap {}

				.details {
					@apply .text-center {}
					flex: 1 1 100%;
					@screen sm {
						flex: 1 1 50%;
						padding-right: 1.5rem; }
					@screen lg {
						flex: 1 1 33.3333%;
						padding-right: 10rem; }
					.loc-title {
						@apply .pb-1 {}
						border-bottom: $border-thickness solid $orange; }
					.phone {
						p {
							margin: 0.25rem 0 1rem;
							span {
								@apply inline-block pt-025 {} } } } // font-bold
					.hours {
						ul {
							li {
								@apply .mb-05 {} } } } }
				.map {
					display: none;
					@screen sm {
						display: block;
						flex: 1 1 50%; }

					@screen lg {
						flex: 1 1 66.6666%;
						padding-top: 3rem; } } } } } }

.map-wrapper {
	@apply .h-full {} }
