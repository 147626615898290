//products page css
#product_index {
	padding-top: 5rem;
	@apply bg-white {}
	@screen md {
		padding-top: 6rem; }
	.product_index {
		@apply .grid grid-cols-1 gap-2 .flex-wrap .items-stretch m-2 relative {}
		@screen sm {
			@apply .grid grid-cols-2 {} }
		@screen lg {
			@apply .grid grid-cols-3 {} }
		li {
			@apply .flex .flex-col .items-center .justify-center relative bg-beige border-orange border {}

			flex: 1 1 50%;
			&::after {
				@apply block {}
				padding-bottom: 100%;
				content: ""; }

			.item-inner {
				@apply .absolute .flex .flex-col .items-center .justify-center border-orange border bg-center bg-cover {}
				top: 0.25rem;
				left: 0.25rem;
				right: 0.25rem;
				bottom: 0.25rem; }

			.button-wrapper {
				// @apply p-025 border-orange border
				// background-image: url(../img/lines.png)
				margin-top: 50%;
				&:hover {
					span {
						@apply bg-orange text-beige {} } } }
			a {
				@apply .text-orange .text-center bg-beige border-orange border-2 block {}
				span {
					@apply block border-orange border px-1 leading-none m-025 flex items-center justify-center uppercase bg-beige font-bold {}
					width: 200px;
					height: 50px; }
				&:hover {
					@apply no-underline {} } } } } }

#product_category {

	section.product-cat {
		@apply .flex .flex-wrap .items-stretch .p-0 {}

		.p-details {
			@apply .bg-black .bg-no-repeat .bg-cover .flex .flex-wrap .items-end {}
			// padding-top: $content-top-offset
			flex: 1 0 100%;
			.description {
				@apply .text-white .uppercase .font-semibold .tracking-wider .text-center {}
				padding-top: 8rem;
				text-shadow: 0px 0px 60px rgba(0, 0, 0, 1);
				flex: 1 1 100%;
				@screen xl {
					flex: 0 1 50%; }

				h1 {
					@apply .text-white {} }

				.content {
					background-color: $blurb-bg-colour-orange; } }

			@screen lg {
				flex: 1 0 60%;
				padding: 5rem; } }

		.p-menu-side {
			@apply .bg-orange .flex .items-center .justify-center {}
			flex: 1 0 100%;
			padding: 5rem 0;

			.product-menu {

				ul {
					list-style-type: none; }

				.title {
					@apply .text-white .text-center {}
					.p-title1 {
						@apply .block .font-subheadings .capitalize {}
						transform: rotate(-5deg); } }

				a {
					@apply .text-white {} } }

			@screen lg {
				@apply .bg-orange .p-15 {}
				// padding-top: $content-top-offset
				flex: 1 0 40%;
 } }				// padding: 10rem

		@screen md {
			min-height: 100vh; } } }
