//nav
ul.nav {
	@apply .fixed .p-1 .flex .flex-row .flex-wrap .text-white .w-full {}
	z-index: 0;
	padding-top: 65px;
	justify-content: space-between;
	justify-content: space-evenly;
	transition: all 200 $easeInOutQuart;
	pointer-events: none;

	li {
		@apply .text-sm .font-semibold .tracking-wide .text-center .flex .flex-col .justify-center {}
		flex: 1 1 auto;

		a {
			@apply .text-white {} }

		&.lv1 {
			@apply .relative {}
			opacity: 0;
			@apply .uppercase {}
			@screen md {
				opacity: 1;
				> ul {
					display: none; }

				&:hover {
					> ul {
						@apply .block .absolute {}
						top: 80%;
						width: 100%;
						background-color: $blurb-bg-colour-dark;
						li {
							a {
								@apply .p-1 .text-sm {} } } } } } }

		&.active > a {
			@apply .underline {} }

		&.active-child > a {
			@apply .underline {} }
		@screen lg {
			@apply .px-05 {}
			flex: 0 1 14.28%; }
		@screen xl {
			@apply .text-md {} } }

	.logo {
		@apply .relative {}
		width: 10%;
		&.home {
			@apply .w-1/5 {} }
		svg {
			@apply .absolute .w-full .h-auto {}
			max-height: 100%; } }

	&.active {
		@apply .flex-col .bg-orange {}
		pointer-events: all;
		li {
			@apply .text-right .font-normal {}
			&.lv1 {
				opacity: 1;
				> a {
					@apply .font-semibold .text-md {}
					display: block; }
				ul {
					.child {
						@apply .mr-0 {}
						> a {
							@apply .py-05 {}
							display: block;
							font-size: 1rem; } } } } } }

	@screen mobile {
		height: 100%;
		// overflow-y: scroll
		.logo {
			display: none; } }

	@screen md {
		@apply .relative .pt-1 .justify-between {}
		pointer-events: all;
		li {
			@apply .mr-0 {} }

		.logo {
			display: block; } } }

#navLogo {
	@apply .relative .h-full {}
	width: 14.28%;
	flex: 0 1 14.28%;
	z-index: -1;

	a {
		transition: opacity ease-in-out 0.25s;
		svg {
			transition: transform ease-in-out 0.25s;
			transform-origin: top;
			transform: scale(1) translateY($header-logo-offset); } }
	&.home {

		svg {
			@apply .absolute .w-full .h-auto {} } }

	@screen xl {
		&.lg {
			a {
				svg {
					transform: scale(2.5) translateY(0.75rem); } } } } }


body[data-theme="dark"] {
	ul.nav {
		@apply .text-white {}
		a {
			@apply .text-white {} }

		li.logo:not(.home) {
			a.white {
				opacity: 1; }
			a.orange {
				opacity: 0; } } } }

body[data-theme="light"] {
	ul.nav {
		@screen sm {
			@apply .text-orange {}
			a {
				@apply .text-orange {} } }

		li.logo:not(.home) {
			a.white {
				opacity: 0; }
			a.orange {
				opacity: 1; } } } }

/* Homepage overwrites: */
body[data-ishome="true"] {
	ul.nav {
		@apply .text-white {}
		a {
			@apply .text-white {} }

		li.logo {
			a.white {
				opacity: 1; }
			a.orange {
				opacity: 0;
				svg {
					transform: scale(1.5); } } } } }

ul.nav {
	li {
		a:hover {
 } } }			// @apply .text-brown
